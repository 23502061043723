<template>
  <div>
    <v-app style="background-color: black; height: 100vh;">
      <img class="imageClassMobile" src="@/assets/4.jpg" @click="openMyMed" />
      <img class="imageClassDesktop" src="@/assets/Desktop_Banner_welcome.png" />
    </v-app>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
         isMobile : false,
        //  image : "https://mymedicineuatimages.s3.ap-south-1.amazonaws.com/publicLunchBanner.png"        
    };
  },
  methods: {
    openMyMed(){
      let userAgent = window.navigator.userAgent;
      console.log(userAgent);
       if (/android/i.test(userAgent)){
       console.log("Android")
       let url = process.env.VUE_APP_DIRECT_LINK_ANDROID;
       window.open(url);
     }
     else if(/Macintosh/.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent)) {
       console.log("IOS")
       let url = process.env.VUE_APP_DIRECT_LINK_IOS
       window.open(url);
     }
     else{
       window.open('https://uat.mymedicine.com.mm/')
     }

    }
  },
  mounted() {
    this.isMobile = this.$device.mobile;
  }
};
</script>
<style>
.introText {
  margin-top: 10%;
  padding-top: 20px;
  color: #1467bf;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 2px;
}

.center {
  text-align: center;
}
.imageClassMobile{
  display: none;
}
.imageClassDesktop{
  display: block;
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
}

@media only screen and (max-width: 425px)
{
  .imageClassMobile
  {
    display: block;
    width: 100%;
  }
  .imageClassDesktop
  {
    display: none;
  }
}

</style>
